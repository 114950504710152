<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
    </v-breadcrumbs>

		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>
		<v-container>
			<v-row>
				<v-col cols="8"> </v-col>
				<v-col cols="4">
					<v-select label="Filtra per data" item-text="Name" item-value="Value" :items="filterByDateOptions"
						v-model="filterByDate" @change="LoadBookings(true)"></v-select>
				</v-col>
			</v-row>
		</v-container>
		<skeleton-table v-if="LoadingData" :columns="4" :actions="1"></skeleton-table>
		<div v-else>
			<v-alert v-if="!LoadingData && items.length === 0" color="info" dark>
				{{ $t("NoBookings") }}
			</v-alert>

			<BookingCard v-for="booking in items" :key="booking.BookingId" :booking="booking"
				@bookingModified="LoadBookings"></BookingCard>

			<div class="text-center">
				<v-pagination v-if="itemsPaging && items.length > 0" v-model="currentPage" :length="itemsPaging.TotalPages"
					:total-visible="5"></v-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import BookingCard from "./components/BookingCard.vue";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	components: { BookingCard },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],
			LoadingData: false,
			items: [],
			itemsPaging: null,
			currentPage: 1,
			pageSize: 10,
			filterByDate: 1,
			filterByDateOptions: [
				{
					Name: "Futuri",
					Value: 1,
				},
				{
					Name: "Passati",
					Value: 2,
				},
			],
		};
	},
	created() {
		this.BookingsService = new CrudClient("CurrentPatient/Bookings");
	},

	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaPatients"),
			disabled: false,
			exact: true,
			to: "/Patients",
		});

		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Patients/Agenda",
		});

		this.LoadBookings();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		getDateString() {
			const today = new Date();
			// Attention: Janyary is 0
			const formattedToday = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;

			return formattedToday;
		},

		async LoadBookings(resetPaging) {
			try {
				let filter = null;
				let orderBy;

				if (this.filterByDate === 1) {
					orderBy = "BookingTime:asc";
				} else if (this.filterByDate === 2) {
					orderBy = "BookingTime:desc";
				}

				if (this.filterByDate === 1 || this.filterByDate === 2) {
					if (filter) filter += ";";
					else filter = "";

					const formattedToday = this.getDateString();

					filter += `BookingTime:${this.filterByDate === 1 ? "gt" : "lt"}:${formattedToday}`;
				}

				if (resetPaging) {
					this.currentPage = 1;
				}

				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					limit: this.pageSize,
					skip: (this.currentPage - 1) * this.pageSize,
					fields: "*",
					filter: filter,
					orderBy: orderBy,
				});
				this.items = res.Data;
				this.itemsPaging = res.Paging;
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<i18n>
{
	"it": {
		"pageTitle": "Agenda appuntamenti",
		"pageMetaDescription": "-",
		"NoBookings": "Nessun appuntamento trovato"
	}
}
</i18n>
