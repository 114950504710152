<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
    </v-breadcrumbs>
		<v-skeleton-loader v-if="LoadingData" type="article"> </v-skeleton-loader>
		<div v-else>
			<v-card color="success" class="text-center pa-5 mb-5">
				<h3 class="text-h5 white--text">
					<v-icon color="white">fas fa-check</v-icon> Grazie per il tuo pagamento
				</h3>
			</v-card>

			<BookingCard v-if="bookingDetails"
				:booking="bookingDetails" @bookingModified="LoadBookingDetails">
			</BookingCard>
		</div>
	</div>
</template>

<script>
import BookingCard from "./components/BookingCard.vue";
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	components: {
		BookingCard,
	},
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
			],

			LoadingData: false,
			bookingDetails: null,
		};
	},

	computed: {
		BookingId() {
			return this.$route.params.BookingId;
		},
		SessionId() {
			return this.$route.query.SessionId;
		},
	},
	created() {
		this.BookingsService = new CrudClient("CurrentPatient/Bookings");
		this.StripePaymentsService = new CrudClient("StripePayments");
	},
	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.areaPatients"),
			disabled: false,
			exact: true,
			to: "/Patients",
		});

		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: false,
			exact: true,
			to: "/Patients/Agenda",
		});

		this.breadcrumbsItems.push({
			text: this.BookingId,
			disabled: true,
			exact: true,
			to: "/Patients/Agenda/" + this.BookingId,
		});

		await this.LoadBookingDetails();
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),
		async LoadBookingDetails() {
			try {
				this.LoadingData = true;

				const res = (await this.StripePaymentsService.Get(
					this.BookingId,
					"Validate?sessionId=" + this.SessionId
				)).PaymentStatus;

				this.$log.debug(res);

				this.bookingDetails = await this.BookingsService.Get(this.BookingId);
				window.fbq("track", "Purchase", { value: this.bookingDetails.Price.toFixed(2), currency: "EUR" });

				window.gtag("event", "purchase", {
					transaction_id: this.bookingDetails.BookingId,
					// Sum of (price * quantity) for all items.
					value: this.bookingDetails.Price.toFixed(2),
					currency: "EUR",
					items: [
						{
							item_id: "STD",
							item_name: "Visita Nutrizionista"
						},
					]
				});
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<style>
</style>
<i18n>
{
	"it": {
		"pageTitle": "Appuntamenti",
		"pageMetaDescription": "-",
		"NoBookings": "Nessun appuntamento trovato"
	}
}
</i18n>
